<template>
  <!--srcviewscompanydetailsBusinessActivitiesSupplyChainvuehtmlStart-->

  <div id="defaultId1" class="pb20">
    <div id="g2ede8" class="operations-content mb10">
      <h2 id="gf7b16" class="operations-head detail_title_20">Locations</h2>
      <el-table :data="tableData" style="width: 100%"
        class=" table_X table_height_30 table_font_size_14 table_contentHeight_40" row-class-name="highlight-row"
        :cell-class-name="cellClassName" empty-text="Please Upload Data">
        <el-table-column label="" width="350" prop="">
          <template slot-scope="scope">
            <el-input autocomplete="off" clearable v-if="scope.$index == inputIndex" v-model="scope.row.addressType"
              @input="handleInput(scope.row)"></el-input>
            <span v-else>{{ scope.row.addressType }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Country" prop="cyen" width="200" align="center">
          <template slot-scope="scope">
            <!-- <el-input
              clearable
              v-if="scope.$index == inputIndex"
              v-model="scope.row.Country"
            ></el-input> -->
            <el-select popper-class="common_select" v-model="Countryvalue" placeholder="Please country"
              style="width:180px" v-if="scope.$index == inputIndex">
              <el-option v-for="item in Countryoptions" :key="item.countryCode" :label="item.nameEn"
                :value="item.countryCode">
              </el-option>
            </el-select>
            <span v-else>{{ scope.row.cyen }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Location" width="200" prop="Location" align="center">
          <template slot-scope="scope">

            <el-input autocomplete="off" clearable v-if="scope.$index == inputIndex" v-model="scope.row.province"
              @input="handleInput(scope.row)"></el-input>
            <span v-else>{{ scope.row.province }}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="Address" prop="ProductDescription" align="center">
          <template slot-scope="scope">
            <el-input autocomplete="off" v-if="scope.$index == inputIndex" clearable v-model="scope.row.address"
              @input="handleInput(scope.row)"></el-input>
            <span v-else>{{ scope.row.address }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Edit" width="200" prop="" align="center">
          <template slot-scope="scope">
            <div class="flex_center">
              <!-- @click="handleEdit(scope.$index, scope.row)" -->
              <el-tooltip class="tbl_opr_tip flex_center" :visible-arrow="false" effect="light"
                content="Applying for on-Site Certification" placement="top">
                <svg t="1718352436222"
                  @click="powerArr[1]?.hasPrivilege ? SelctchangEchartsitem(scope.row) : $bus.$emit('show-permission-dialog', '您没有权限访问该功能！')"
                  style="margin-right: 30px;" class="icon" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" p-id="4748" width="2.57rem" height="2.57rem">
                  <path
                    d="M656.497778 676.067556c68.266667-78.506667 136.533333-179.655111 136.533333-276.366223a281.031111 281.031111 0 1 0-562.062222 0c0 97.735111 68.266667 199.111111 136.533333 276.366223-119.466667 21.731556-192.284444 68.266667-192.284444 122.88C175.217778 886.556444 349.297778 932.977778 512 932.977778s336.782222-46.648889 336.782222-134.257778c0-54.385778-72.817778-100.920889-192.284444-122.652444z m-145.635556-519.964445a243.825778 243.825778 0 0 1 243.484445 243.598222c0 147.911111-192.284444 323.128889-242.346667 367.388445-50.062222-42.097778-244.622222-215.04-244.622222-367.388445a245.304889 245.304889 0 0 1 243.484444-243.598222z m0 739.555556c-170.666667 0-299.235556-51.2-299.235555-96.711111 0-30.72 61.44-69.290667 178.631111-87.608889a49.265778 49.265778 0 0 1 5.688889-1.137778 1168.384 1168.384 0 0 0 105.813333 96.711111 17.180444 17.180444 0 0 0 12.515556 4.664889 16.725333 16.725333 0 0 0 12.515555-4.664889 1035.377778 1035.377778 0 0 0 101.262222-95.573333c120.604444 18.204444 184.32 58.026667 184.32 88.746666-2.275556 44.373333-130.844444 95.573333-301.511111 95.573334z"
                    p-id="4749" fill="#1290C9"></path>
                  <path
                    d="M686.08 403.000889A174.08 174.08 0 1 0 512 577.080889a174.762667 174.762667 0 0 0 174.08-174.08z m-308.337778 0a136.533333 136.533333 0 0 1 136.533334-136.533333 140.174222 140.174222 0 0 1 113.777777 60.302222l-127.431111 125.155555-60.302222-60.302222a19.342222 19.342222 0 0 0-27.306667 27.420445l73.955556 72.817777a21.048889 21.048889 0 0 0 13.653333 5.688889 17.976889 17.976889 0 0 0 13.653334-5.688889l129.706666-129.706666a116.053333 116.053333 0 0 1 5.688889 39.822222 136.533333 136.533333 0 1 1-271.928889 1.024z"
                    p-id="4750" fill="#1290C9"></path>
                </svg>
              </el-tooltip>
              <el-tooltip class="tbl_opr_tip flex_center" :visible-arrow="false" effect="light" content="Edit"
                placement="top" v-if="userType && hasPower == 1">
                <svg t="1718352489958" class="icon" @click="handleEdit(scope.$index, scope.row)" viewBox="0 0 1024 1024"
                  version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4940" width="2.57rem" height="2.57rem">
                  <path
                    d="M769.824 291.542L666.519 188.237c-15.802-15.802-41.421-15.799-57.224 0l-361.94 361.94c-12.357 12.358-11.885 12.825-11.889 39.389-0.005 132.545 0.064 132.965 0.064 132.965s0.42 0.07 132.969 0.065c26.561 0 27.027 0.468 39.39-11.891L769.824 348.77c15.805-15.802 15.809-41.421 0-57.228zM394.826 664.313c-14.95 14.949-15.478 14.416-45.967 14.416-69.291 0-69.404-0.117-69.404-0.117s-0.121-0.114-0.121-69.409c0-30.489-0.529-31.017 14.416-45.963l240.425-240.425 101.071 101.076-240.42 240.422z m270.149-270.151L563.903 293.086l46.567-46.566c27.437-27.437 27.852-27.023 54.879 0.006l46.187 46.187c27.033 27.026 27.447 27.442 0.007 54.885l-46.568 46.564zM811.435 718.375H637.664c-11.609 0-21.02-9.411-21.02-21.021 0-11.611 9.41-21.021 21.02-21.021h173.771c11.612 0 21.021 9.41 21.021 21.021 0 11.61-9.409 21.021-21.021 21.021zM811.435 847.612H256.487c-11.612 0-21.021-9.411-21.021-21.021 0-11.609 9.408-21.021 21.021-21.021h554.947c11.612 0 21.021 9.411 21.021 21.021 0.001 11.61-9.408 21.021-21.02 21.021z"
                    fill="#1290C9" p-id="4941"></path>
                </svg>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- v-show="userType&&hasPower==1" -->
      <div class="last-colmun">
        <span v-if="inputIndex == -1" class="contclass" @click="addInput(tableData.length)"><span
            class="mr10">+</span><span>Add</span>
        </span>
        <div v-else style="text-align: center">
          <span @click="CancelAdd()" class="contclass">Cancel</span>
          <span class="jiange">|</span>
          <span class="contclass" @click="Saved('edit')">Saved</span>
        </div>
      </div>
      <!-- <div v-if="inputIndex == -1" style="text-align: center; margin: 20px">
        <span class="contclass show_All">Show All</span>
      </div> -->
    </div>
    <div @click="powerArr[1]?.hasPrivilege ? '' : $bus.$emit('show-permission-dialog', '您没有权限访问该功能！')"
      :class="powerArr[1]?.hasPrivilege ? '' : 'vaguesuo'">
      <div id="g2ede8" class="operations-content" :class="powerArr[1]?.hasPrivilege ? 'operations-content ' : 'vague'">
        <h2 id="gf7b16" class="operations-head detail_title_20">Company Locatiions in Map</h2>
        <div style="margin: 20px 0px">
          <el-select ref="upSelect3" v-click-outside="clickOutSide3" popper-class="common_select"
            @change="SelctchangEcharts()" v-model="valuecountry" filterable clearable placeholder="Select address type">
            <el-option v-for="item in tableData" :key="item.addressType" :label="item.addressType"
              :value="item.addressType" size="300">
            </el-option>
          </el-select>

          <!-- <el-select popper-class="common_select" v-model="value2" filterable clearable style="margin-left: 20px;"
          placeholder="Select a Country or region">
          <el-option v-for="item in tableData" :key="item.aaaid" :label="item.country" :value="item.country">
          </el-option>
        </el-select> -->
        </div>
        <div class="operations-box">
          <div ref="worldMap" style="width: 100%; height: 600px"></div>
        </div>
        <!-- <PermissionDialog></PermissionDialog> -->
        <ReLogin :reLoginPop="reLoginVis"></ReLogin>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getCompanyLocations,
  getPartPrivilege,
  addAddressInfo,
  editAddressInfo,
} from "@/api/companyApi";
import PermissionDialog from '@/components/power/PermissionDialog.vue';
import ReLogin from "../../../../components/ReLogin.vue";

export default {
  name: "",
  props: {},

  components: { PermissionDialog, ReLogin, },
  mounted() {
    this.getlist()
    this.getPartPrivilege()
    if (this.userInfo.userInfo.userType == 3) {
      this.userType = true
    }
    this.$emit("UpdatamarkTotalList", []);
    this.getCountry()

  },
  data() {
    return {
      reLoginVis: false,
      userType: false,
      powerArr: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      Countryvalue: '',
      Countryoptions: [

      ],
      options: [],
      valuecountry: '',
      value2: [],
      page: {
        pageNo: 1,
        pageSize: 5,
        total: 0,
      },
      tableDatamap: [],
      tableData: [

      ],
      inputIndex: -1,
      nodes: [], //{ name: 'Climate change' }
      links: [],
      province: '',
      address: '',
      addresstype: '',
      hasPower: 0,
    };
  },
  methods: {
    clickOutSide3() {

      if (this.$refs.upSelect3) {
        this.$refs.upSelect3.visible = false

      }

    },
    SelctchangEchartsitem(value) {
      this.tableDatamap = this.tableData.filter(
        (item) => item.addressType == value.addressType
      );
      this.$nextTick(() => this.renderWorldMap());
    },
    SelctchangEcharts() {
      if (this.valuecountry) {
        this.tableDatamap = this.tableData.filter(
          (item) => item.addressType == this.valuecountry
        );
      } else {
        this.tableDatamap = this.tableData;
      }

      this.$nextTick(() => this.renderWorldMap());
    },
    handleInput(row) {
      this.province = row.province
      this.address = row.address
      this.addresstype = row.addressType
    },

    addInput(index) {
      this.inputIndex = index;
      this.tableData.push({ ...this.tableData[index - 1] });

    },
    Saved(value) {

      let params = [
        {
          aaaId: this.$route.query.id3a,
          type: 1,
          customAccount: this.userInfo.userInfo.email,
          dataJson: {
            "country": this.Countryvalue,
            "province": this.province,
            "address": this.address,
            "addressType": this.addresstype,
          },
        },
      ];
      params.map((item) => {
        item.dataJson = JSON.stringify(item.dataJson)

      });

      if (this.page.total >= this.tableData.length) {
        editAddressInfo(params).then(res => {
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.inputIndex = -1;
            this.getlist()
          }


        })
      } else {
        addAddressInfo(params).then(res => {
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.inputIndex = -1;
            this.getlist()
          }


        })
      }

    },
    getCountry() {
      this.Countryoptions = JSON.parse(localStorage.getItem('countryList'))[0].countryList;
    },
    getPartPrivilege() {
      getPartPrivilege({
        moduleId: this.$route.query.idpower
      }).then(res => {
        this.powerArr[0] = (res.data.filter(item => item.id == '425'))[0]
        this.powerArr[1] = (res.data.filter(item => item.id == '426'))[0]
      })

    },
    // 获取页面数据
    getlist() {
      const id3a = this.$route.query.id3a;
      getCompanyLocations({ id3a: id3a, page: this.page.pageNo, pageSize: this.page.pageSize }).then(res => {
        if (res.code == 200) {
          this.tableData = res.data.addressInfos
          this.page.total = res.data.addressInfos.length
          this.tableDatamap = res.data.addressInfos
          this.hasPower = res.data.hasPower
          this.renderWorldMap();


        } else {
          this.tableData = []
        }
      }).catch(err => {
        this.reLoginVis = String(err).includes('403')
      })
    },
    CancelAdd() {
      this.inputIndex = -1;
      this.getlist()
      // this.tableData.push({ ...this.tableData[index - 1] });
      // this.tableData.pop();
    },

    handleEdit(index, row) {
      this.getlist()
      this.Countryvalue = row.country
      this.inputIndex = index;

    },
    cellClassName({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return "highlight-cell"; // 设置你想要的背景色
      }
    },
    renderWorldMap() {
      this.echartsBox = this.$echarts.init(this.$refs.worldMap);
      const highlightedCountries = ['United States'];
      // 公司所在的国家坐标（这里以中国为例）
      let mapdata = [];
      let mapCountry = []
      this.tableDatamap.map((item) => {
        mapdata.push({
          name: item.addressType,
          value: [item.latude, item.lotude],

        });
        mapCountry.push(
          item.cyen
        )
      });


      let series = [
        {
          type: "effectScatter",
          coordinateSystem: "geo",
          zlevel: 2,
          rippleEffect: {
            period: 4,
            brushType: "fill",
            scale: 0,
          },

          label: {
            normal: {
              show: true,
              position: "right",
              offset: [5, 0],
              offset: [5, 0],
              formatter: "{b}",
              padding: [5, 20, 5, 20], // 设置内边距
              borderWidth: 1, // 设置边框宽度
              borderColor: '#1290C9', // 设置边框颜色
              borderRadius: 15, // 设置边框圆角
              backgroundColor: 'rgba(255, 255, 255, 0.8)', // 设置背景颜色
            },
            emphasis: {
              show: true,
            },
          },

          symbol: "image://" + require("@/assets/images/地址.png"),
          symbolSize: [16, 16], // 图片的大小
          itemStyle: {
            normal: {
              show: false,
            },
          },
          data: mapdata,
        },
      ];
      //   ECharts 配置项

      const option = {
        // backgroundColor: '#B0CCE2',

        visualMap: {
          //图例值控制
          min: 0,
          max: 10000,
          show: false,
          calculable: true,
          color: ["#ff9500"],
          textStyle: {
            color: "#fff",
          },
        },
        geo: {
          map: "world",
          label: {
            emphasis: {
              show: true,
              color: "#fff",
            },
          },
          roam: true, //是否允许缩放
          layoutCenter: ["50%", "50%"], //地图位置
          layoutSize: "180%",
          // 设置默认颜色
          itemStyle: {
            normal: {
              areaColor: "#edf3f3",
              borderColor: "#9d9f9f",
            },
            emphasis: {
              areaColor: "#e7eaed ",
            },
          },
          regions: mapCountry.map(country => ({
            name: country,
            itemStyle: {
              areaColor: '#1290C9', // 高亮颜色
            },
          })),
        },

        series: series,
      };

      this.echartsBox.setOption(option);
    },
  },
  computed: {},

};
</script>
<style scoped>
.operations-content {
  padding: 2.142857142857143em 2.857142857142857em;
  height: 100%;
  background: #FFFFFF;
  box-shadow: 0em 0.14285714285714285em 1em 0em rgba(0, 0, 0, 0.12);
  border-radius: 0.2857142857142857em;
}

.operations-head {
  font-size: 2.14em;
  /* font-family: "Arial Bold"; */
  font-family: 'DMSans Bold';
  color: #1a2332;
  text-align: left;
  font-style: normal;
  display: flex;
  justify-content: space-between;
}

.operations-content /deep/ .el-input__inner {
  height: 3.5714285714285716rem;
  border-radius: 0.2857142857142857em;
  border: 0.07142857142857142em solid #8497AB;
}

.bgyinying {
  height: 0.7142857142857143em;
  width: 100%;
  background-color: #e2e2e2;
}

.last-colmun {
  padding: 2em 0em;
  border-bottom: 0.07142857142857142em solid #cccccc;
}

.contclass {
  font-size: 1.4285714285714286em;
  color: #1290c9;
  font-style: normal;
}

.jiange {
  color: #cccccc;
  margin: 0 1.42rem;
  font-size: 1.4285714285714286rem;
  /* font-size: 1.25em; */
}

/deep/ .highlight-cell {
  background-color: #f2f5f9 !important;
}

/deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  line-height: 2.14rem;
}

/deep/ .el-table th.el-table__cell>.cell {
  font-family: 'DMSans Bold';
}

/deep/ .el-table {
  margin-top: 1.4285714285714286em;
}

/deep/ .el-table th.el-table__cell {
  background-color: #f2f5f9 !important;
}

.operations-box {
  margin-top: 2.142857142857143em;
}

/deep/ .el-select {
  width: 30em;
  height: 3.5714285714285716rem;
}

/deep/.el-input__inner:focus {
  border: 0.07142857142857142em solid #8497AB;
}

.bg {}

/*cssStart*/

/*cssEnd*/
/deep/ .el-table th {
  padding: 0.35714285714285715rem 0;
  height: 3.5714rem;
}

.mr10 {
  margin-right: 0.7142857142857143rem;
}
</style>